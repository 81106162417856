import React from "react";
import Link from "next/link";
import { Title } from "@vshp/ui-components";
import Head from "next/head";

const PageNotFound = (props) => {
  const errorContent = (
    <div className="not-found">
      <div className="not-found__inner">
        <div className="not-found__error">{props.error}</div>
        <Title decor={false} className="not-found__title">
          {props.reason}
        </Title>

        <div className="not-found__text">{props.details}</div>

        <div className="not-found__btn">
          <Link className="btn btn_outline btn_max-width-sm" href="/">
            Главная страница
          </Link>
        </div>
      </div>
    </div>
  );

  return props.full ? (
    <>
      <Head>
        <title>Страница не найдена</title>
        <meta property="og:title" content="Страница не найдена" key="title" />
      </Head>
      {errorContent}
    </>
  ) : (
    errorContent
  );
};

PageNotFound.defaultProps = {
  full: true,
  error: 404,
  reason: "Кажется такой страницы не существует",
  details: "Возможно ссылки не существовало или она устарела. Перепроверьте ее правильность и обновите страницу.",
};

export default PageNotFound;
